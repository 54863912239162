angular.module('fingerink')
    .directive('avanzadaBase', function () {
        return {
            restrict: 'E',
            replace: true,
            templateUrl: 'directives/widgets/avanzada-base.tpl.html',
            scope: {
                data: '=',
                editable: '=',
                fillable: '=',
                color: '=',
                borrar: '&',
                selected: '=',
                campoId: '='
            },
            controllerAs: 'controller',
            controller: 'avanzadaBaseCtrl',

            link: function ($scope, element, attrs) {
                function applyCss(){
                    element.css('height', $scope.data.height + '%');
                    element.css('left', $scope.data.left + '%');
                    element.css('width', $scope.data.width + '%');
                    element.css('top', $scope.data.top + '%');
                }

                function calcularBounds(){
                    var parent = element[0].parentElement.getBoundingClientRect();
                    var child = element[0].getBoundingClientRect();
    
                    $scope.data.width = child.width / parent.width * 100;
                    $scope.data.height = child.height / parent.height * 100;
                    $scope.data.top = (child.top - parent.top) / parent.height * 100;
                    $scope.data.left = (child.left - parent.left) / parent.width * 100;
                    applyCss();
                    $scope.$broadcast('boundsChanged');
                }

                if($scope.editable){
                    element.resizable({
                        containment: element[0].parentElement,
                        stop: calcularBounds//,
                        //aspectRatio: 1
                    });
                    $scope.onStop = calcularBounds;
                }
                applyCss();
            },
        };
    })
    .controller('avanzadaBaseCtrl', function ($rootScope, $scope, $timeout, $window) {
        var that = this;
    });
